import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

// PS Modules
import { TabsModule } from '@purespectrum1/ui/tabs';
import { ModalModule } from '@purespectrum1/ui/modal';
import { ButtonModule } from '@purespectrum1/ui/button';
import { SliderModule } from '@purespectrum1/ui/slider';
import { DropdownModule } from '@purespectrum1/ui/dropdown';
import { AccordionModule } from '@purespectrum1/ui/accordion';
import { GoogleTagManagerModule } from '@purespectrum1/ui/tracking';
import {
  ManageSurveyTabsModule,
  TreeDropdownModule,
} from '@purespectrum1/ui/marketplace';
import { SurveyTopViewModule } from '@purespectrum1/ui/survey-top-view';
import { SelectDropdownModule } from '@purespectrum1/ui/select-dropdown';
import { EnvironmentConfig } from '@purespectrum1/ui/marketplace/shared/interfaces/environment.interface';
import { FlagModule } from '@purespectrum1/ui/flag';

// NG Modules
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Guards
import { AuthGuard } from '../auth/auth.guard';

// Environments
import { environment } from '../../environments/environment';

// Modules
import { LayoutModule } from '../layout/layout.module';
import { HasRoleModule } from '../shared/ui/has-role/has-role.module';
import { SortTableModule } from '../sort-table-directive/sort-table.module';
import { SearchFilterPipeModule } from '../shared/pipes/filter-pipe.module';
import { AdjustmentModule } from '../shared/ui/adjustment/adjustment.module';
import { SurveyTableModule } from '../shared/ui/surveys-table/surveys-table.module';
import { ConfigEnabledModule } from '../shared/ui/config-enabled/config-enabled.module';
import { WatchVisibilityModule } from '../shared/ui/watch-visibility/watch-visibility.module';
import { InputDecimalRestrictionModule } from '../shared/ui/input-decimal-restriction/input-decimal-restriction.module';

// Components
import { TosComponent } from './tos/tos.component';
import { DashboardComponent } from './dashboard.component';
import { SurveyDetailComponent } from './survey-detail/survey-detail.component';
import { SurveyListingComponent } from './survey-listing/survey-listing.component';
import { ChangeSurveyStatusModalComponent } from './change-survey-status-modal/change-survey-status-modal.component';
import { DashboardPreferenceModalComponent } from './dashboard-preference-modal/dashboard-preference-modal.component';
import { ProjectManagerSearchModalComponent } from './project-manager-search-modal/project-manager-search-modal.component';
import { BulkEditComponent } from './bulk-edit/bulk-edit.component';
import { CardModule } from '@purespectrum1/ui/card';
import { BulkOptionsComponent } from './bulk-edit/bulk-options/bulk-options.component';
import { BillingNumberTooltipComponent } from './bulk-edit/bulk-edit-container/bulkd-edit-tooltip/billing-number-tooltip.component';
import { ChartsModule } from '@purespectrum1/ui/charts';

// Pipes
import { CapingPipe } from '../shared/pipes/cap-pipe';
import { CurrencySymbolPipe } from '../shared/pipes/currency-units-pipe';
import { SurveyRouterLinkPipe } from './pipes/survey-router-link.pipe';
import { WidgetButtonComponent } from './widget/widget-button/widget-button.component';
import { SurveySearchComponent } from './survey-search/survey-search.component';
import { WidgetContainerComponent } from './widget/widget-container/widget-container';
import { BulkOptionConfirmationComponent } from './bulk-option-confirmation/bulk-option-confirmation.component';
import { TableModule } from '@purespectrum1/ui/table';
import { SurveyTableComponent } from './survey-table/survey-table.component';

import { BulkEditContainerComponent } from './bulk-edit/bulk-edit-container/bulk-edit-container.component';
import { HealthCheckWidgetComponent } from './survey-table/health-check-widget/health-check-widget.component';
import { DashboardClearSelectionConfirmation } from './dashboard-clear-selection-confirmation/dashboard-clear-selection-confirmation.component';
import { SwitchMultiCasePipeModule } from '../shared/pipes/switch-multi-case-pipe.module';
import { TruncateWordPipeModule } from '../shared/pipes/truncate-word-pipe.module';
import { HealthCheckCountDisplayComponent } from './survey-table/health-check-widget/health-check-count-display/health-check-count-display.component';
import { HealthCheckKpiDropdownComponent } from './survey-table/health-check-widget/health-check-kpi-dropdown/health-check-kpi-dropdown.component';
import { MultiCountryInfoListingModule } from '../shared/ui/multi-country-info-listing/multi-country-info-listing.module';

@NgModule({
  providers: [],
  declarations: [
    BulkEditComponent,
    BulkEditContainerComponent,
    BillingNumberTooltipComponent,
    BulkOptionConfirmationComponent,
    BulkOptionsComponent,
    CapingPipe,
    ChangeSurveyStatusModalComponent,
    CurrencySymbolPipe,
    DashboardComponent,
    DashboardPreferenceModalComponent,
    ProjectManagerSearchModalComponent,
    SurveyDetailComponent,
    SurveyListingComponent,
    SurveyRouterLinkPipe,
    SurveySearchComponent,
    TosComponent,
    WidgetButtonComponent,
    WidgetContainerComponent,
    BulkOptionConfirmationComponent,
    SurveyTableComponent,
    HealthCheckWidgetComponent,
    DashboardClearSelectionConfirmation,
    HealthCheckCountDisplayComponent,
    HealthCheckKpiDropdownComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    ModalModule,
    SliderModule,
    AdjustmentModule,
    NgSelectModule,
    AccordionModule,
    Daterangepicker,
    CardModule,
    WatchVisibilityModule,
    TruncateWordPipeModule,
    TreeDropdownModule,
    RouterModule.forChild([
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: DashboardComponent,
          },
          {
            path: ':id',
            component: SurveyDetailComponent,
          },
        ],
      },
    ]),
    TabsModule,
    InfiniteScrollModule,
    DropdownModule,
    HasRoleModule,
    SurveyTableModule,
    SelectDropdownModule,
    LayoutModule,
    FlagModule,
    InputDecimalRestrictionModule,
    SurveyTopViewModule,
    SortTableModule,
    ManageSurveyTabsModule.forRoot({ environment } as EnvironmentConfig),
    ConfigEnabledModule,
    GoogleTagManagerModule,
    SearchFilterPipeModule,
    DragDropModule,
    TableModule,
    SwitchMultiCasePipeModule,
    MultiCountryInfoListingModule,
    ChartsModule,
  ],
  exports: [
    BulkEditComponent,
    BulkEditContainerComponent,
    BulkOptionsComponent,
  ],
})
export class DashboardModule {}
