import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Company,
  GetQuestionApiResponse,
  LocaleBasedAnswersArrayInterface,
  Localization,
  ModifiedDataInterface,
  Options,
  Category,
  CategoryPayload,
} from '../questions-library-services/questions-library-services.interface';
import { Constants, messages } from '../questions-library.constants';
import { QuestionsLibraryService } from '../questions-library-services/questions-library-services.service';
import { AuthService } from '../../../app/auth/auth.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notifyMessage } from '../../constants/notify-message';
import { CompanyResponseObject } from '../../shared/interfaces/company.interface';
import { ModalService } from '@purespectrum1/ui/modal';
import { ConfirmationModalComponent } from '../../shared/ui/confirmation-modal/confirmation-modal.component';
import { randomString } from '../../utils/random-string';

@Component({
  selector: 'ps-add-questions',
  templateUrl: './add-questions.component.html',
  styleUrls: ['./add-questions.component.css'],
})
export class AddQuestionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formMode: boolean = false;
  @Input() isEditMode: boolean = false;
  @Input() questionData: GetQuestionApiResponse = <GetQuestionApiResponse>{};
  @Input() companiesResponse: CompanyResponseObject[] = [];

  @Output() formModeChange = new EventEmitter<boolean>();
  @Output() reloadQuestions = new EventEmitter<boolean>();

  public companies: Company[] = [];
  public selectedCompanies: Company[] = [
    {
      id: 0,
      name: 'ALL',
    },
  ];
  public classValues: Options[] = [];
  public questionTypes: Options[] = [];
  public randomOptions: Options[] = [];
  public rangeUnits: Options[] = [];
  public answerOrder: Options[] = [];
  public stem1BtnDisabled: boolean = false;
  public stem2BtnDisabled: boolean = false;
  public buyerTextBtnDisabled: boolean = false;
  public isEditingDisabled: boolean = false;
  public isPrivacyPolicyQuestion: boolean = false;
  public userType: string = '';
  public localizations: Localization[] = [];
  public clonedLocalization: Localization = {} as Localization;
  public isCloneLocalization: boolean = false;
  public isSegmentation: boolean = false;
  public categories: Category[] = [];
  public isUserHasQuestionLibraryAccess: boolean = false;
  public canUserAddCategory: boolean = false;
  form!: FormGroup;

  readonly CONSTANTS = Constants;

  private _userCmp?: number;
  private _subscriptions: Subscription = new Subscription();

  constructor(
    private _authService: AuthService,
    private _toastrService: ToasterService,
    private _fb: FormBuilder,
    private _questionLibraryService: QuestionsLibraryService,
    private _modal: ModalService
  ) {
    this.classValues = Constants.CLASS_OPTIONS;
    this.questionTypes = Constants.QUESTION_TYPE_OPTIONS;
    this.userType = this._authService.userType;
    this.answerOrder = Constants.ANSWER_ORDER_OPTIONS;
    this._userCmp = this._authService.user?.cmp;
    this.rangeUnits = Constants.RANGE_UNITS_OPTIONS;
    this.canUserAddCategory = this._canAddCategory();
  }

  ngOnInit(): void {
    this._mapBuyerCompanies();
  }

  ngOnChanges(): void {
    this._generateForm();
    this._mapBuyerCompanies();
    this._getCategories();

    this._subscriptions.add(
      this.form.controls?.class?.valueChanges.subscribe((values) =>
        this._filterQuestionTypes(values)
      )
    );

    if (this.isEditMode) {
      this._patchFormDetails();
    } else {
      this.localizations = [];
      this._setDefaultFormValues();
      this._manageDisablingButtons();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _generateForm() {
    this.form = this._fb.group({
      hideQuestion: [false],
      company: [
        [
          {
            id: 0,
            name: 'ALL',
          },
        ],
      ],
      class: this._fb.group({
        key: [3],
        value: ['Custom'],
      }),
      recency: [''],
      desc: ['', Validators.required],
      category: ['', Validators.required],
      type: this._fb.group({
        key: [1],
        value: ['Single Punch'],
      }),
      answerOrder: this._fb.group({
        key: [1],
        value: ['Alpha/Numeric'],
      }),
      stem1: [''],
      stem2: [''],
      buyertext: [''],
      tags: [''],
      units: [''],
      create_survey_question_order: [''],
    });

    this._filterQuestionTypes({
      key: Constants.MAPPED_CLASS_TYPE.EXTENDED,
    });
  }

  private _mapBuyerCompanies() {
    this.companies = this.companiesResponse.map(({ id, name }) => {
      return { id, name };
    });
  }

  private _setDefaultFormValues() {
    if (this.userType === 'buyer') {
      this.form.controls.class.setValue({
        key: 3,
        value: 'Custom',
      });
      // For Buyer, only single punch, and multi punch question types should be shown
      this.questionTypes = this.questionTypes.filter((qType) =>
        [
          Constants.QUESTION_TYPE.SINGLEPUNCH,
          Constants.QUESTION_TYPE.MULTIPUNCH,
        ].includes(Number(qType.key))
      );
      const company = this.companies.find(
        (_company: any) => _company.id === this._userCmp
      );
      if (company) {
        this.form.controls.company.setValue([company]);
      }
    }
  }

  private _filterQuestionTypes(values: { key: string | number }) {
    const dataQualityFilter = [Constants.QUESTION_TYPE.INPUT];
    if (values.key === Constants.MAPPED_CLASS_TYPE.SEGMENTATION) {
      this.questionTypes = Constants.QUESTION_TYPE_OPTIONS;
    } else {
      this.questionTypes = Constants.QUESTION_TYPE_OPTIONS.filter(
        (type) => !dataQualityFilter.includes(type.key)
      );
    }
  }

  public validateRecency(event: Event) {
    const _value = parseInt((<HTMLInputElement>event.target).value);
    if (_value < 0 || _value > 365 || isNaN(_value)) {
      (<HTMLInputElement>event.target).value = '';
      this._toastrService.warning(messages.WARNING_MESSAGE.INCORRECT_VALUE);
      this.form.controls.recency.setValue('');
    }
  }

  public selectCompany(company: Company) {
    this.form.patchValue({
      company: [company],
    });
  }

  public selectCompanies(companies: Company[]) {
    this.selectedCompanies = this._isAllCompanySelected(companies)
      ? [{ id: 0, name: 'ALL' }]
      : companies;
    this.form.patchValue({
      company: this.selectedCompanies,
    });
  }

  private _isAllCompanySelected(companies: Array<Company>) {
    return companies.some((company) => company.id === 0);
  }

  private _patchCompany() {
    if (this.questionData.class !== Constants.MAPPED_CLASS_TYPE.CUSTOM) {
      return [
        {
          id: this.questionData.cmp[0],
          name: this._getNameById(
            this.companies,
            this.questionData.cmp[0],
            'id',
            'name',
            'ALL'
          ),
        },
      ];
    }

    return (
      this.companies.filter((company) =>
        this.questionData.cmp.includes(company.id)
      ) || [
        {
          id: 0,
          name: 'ALL',
        },
      ]
    );
  }

  public clickRespId(id: string) {
    const subStrToAdd = !this.isEditMode
      ? '%%respId%%'
      : `%%${this.questionData.qualification_code}%%`;
    const valueToSet = (this.form.controls[id].value || '').concat(
      this.form.controls[id].value ? ' ' + subStrToAdd : subStrToAdd
    );
    this.form.controls[id].setValue(valueToSet);
    this._setButtonDisabled(true, id);
  }

  public checkAndSetButtonDisabled(id: string) {
    if (this.isEditMode) {
      if (this.form.controls[id].value) {
        this._setButtonDisabled(true, id);
      } else {
        this._setButtonDisabled(false, id);
      }
    } else {
      if (
        this.form.controls[id].value &&
        this.form.controls[id].value.search('%%respId%%') !== -1
      ) {
        this._setButtonDisabled(true, id);
      } else {
        this._setButtonDisabled(false, id);
      }
    }
  }

  private _setButtonDisabled(booleanVal: boolean, id: string) {
    if (id === 'stem1') {
      this.stem1BtnDisabled = booleanVal;
    } else if (id === 'stem2') {
      this.stem2BtnDisabled = booleanVal;
    } else if (id === 'buyertext') {
      this.buyerTextBtnDisabled = booleanVal;
    }
  }

  onSubmitLocalization(localization: Localization) {
    this.localizations.push(localization);
  }

  async checkAndDeleteLocalization(index: number, e: Event) {
    e.stopPropagation();

    const isProceed = await this._openModalAndConfirmResponse(
      Constants.MODAL.DELETE_LAST_LOCALIZATION.TEXT
    );

    if (!isProceed) {
      return;
    }

    //Remove localization before save question (just in local)
    if (!this.questionData.qualification_code) {
      this.localizations.splice(index, 1);
      return;
    }

    //Find the question localization
    const localizationToDelete = this._getLocalizationToDelete(index);

    //Call api to delete question and remove from local if have more the one question and localization
    if (this.localizations.length !== 1 && localizationToDelete) {
      this._deleteQuestionLocalization(localizationToDelete);
      this.localizations.splice(index, 1);
      return;
    }

    // If there is only one localization present, and that last localization is attempted to be deleted
    this._deleteQuestion();
  }

  private _getLocalizationToDelete(index: number) {
    return this.localizations[index] && this.localizations[index].locale
      ? this.localizations[index].locale
      : '';
  }

  saveChanges() {
    if (!this.isEditMode) {
      // Add question mode
      this.addQuestion();
    } else if (!this.isEditingDisabled) {
      // Edit question mode and when editing is allowed
      this.updateQuestion();
    }
  }

  addQuestion() {
    if (this._validateFields()) {
      const formData = this.form.value;
      const _question = this._mapQuestionPayload(formData);
      // we are returning both the condition and locale for which this is happening
      const mismatchAnswerObject = this._validateLocalization(_question);
      if (mismatchAnswerObject[0]) {
        this._toastrService.error(
          messages.ERROR_MESSAGE.ANSWERS_OBJECT_MISMATCH_PART_ONE +
            `${mismatchAnswerObject[1]}` +
            messages.ERROR_MESSAGE.ANSWERS_OBJECT_MISMATCH_PART_TWO
        );
        return;
      }
      this._questionLibraryService.addQuestion(_question).subscribe(
        (data) => {
          this.formModeChange.emit(false);
          this.reloadQuestions.emit(true);
          this._toastrService.success(
            notifyMessage.successMessage.QUESTION_LIBRARY.QUESTION_ADDED
          );
        },
        (error) => {
          this._displayErrorMessage(error);
        }
      );
    }
  }

  private _displayErrorMessage(
    error: any,
    defaultErrorMessage: string = notifyMessage.errorMessage.API_ERROR
  ) {
    const errorMessage =
      error && error.error && error.error.ps_api_response_message
        ? error.error.ps_api_response_message
        : defaultErrorMessage;
    this._toastrService.error(errorMessage);
  }

  private _validateFields() {
    if (this.form.status === 'INVALID') {
      this._toastrService.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.ENTER_ALL_FIELDS
      );
      return false;
    }
    const formData = this.form.value;
    if (formData.class.key === 1) {
      this._toastrService.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.CORE_QUESTION_NOT_ALLOWED
      );
      return false;
    }

    if (!this.localizations.length) {
      this._toastrService.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.ADD_LOCALIZATION
      );
      return false;
    }

    {
      let componentMissing = false;
      this.localizations.forEach((locale) => {
        if (locale.create_survey_position && !locale.create_survey_component) {
          componentMissing = true;
        }
      });
      if (componentMissing) {
        this._toastrService.warning(
          notifyMessage.warningMessage.QUESTION_LIBRARY.REQUIRED_COMPONENT_TYPE
        );
        return false;
      }
    }

    if (formData.stem1 && this._isStem1Invalid(formData)) {
      this._toastrService.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.REQUIRED_STEM_RESPID
      );
      return false;
    }
    return true;
  }

  private _isStem1Invalid(formData: any) {
    // The sub string required in stem1
    const stem1RequiredSubStr = !this.isEditMode
      ? '%%respId%%'
      : `%%${this.questionData.qualification_code}%%`;
    return !formData.stem1.includes(stem1RequiredSubStr);
  }

  private _mapQuestionPayload(formData: any) {
    return {
      attribute_id:
        this.isEditMode && this.questionData.attribute_id
          ? this.questionData.attribute_id
          : randomString(5),
      cmp: formData.company.map((cmp: Company) => cmp.id),
      is_hidden: formData.hideQuestion,
      desc: formData.desc,
      category: formData.category,
      type: formData.type.key,
      class: formData.class.key,
      stem1: formData.stem1,
      stem2: formData.stem2,
      buyer_text: formData.buyertext,
      create_survey_question_order: formData.create_survey_question_order,
      answer_order: formData.answerOrder.key,
      localizations: this.localizations,
      ...(formData?.tags?.length && {
        tags: String(formData.tags.map((tag: { label: string }) => tag.label)),
      }),
      ...(formData.recency && {
        recency: formData.recency,
      }),
      units: formData.units?.key,
    };
  }

  updateQuestion() {
    const isValid = this._validateFields();
    if (!isValid) {
      return;
    }
    const formData = this.form.value;
    const questionToUpdate = this._mapQuestionPayload(formData);
    const mismatchAnswerObject = this._validateLocalization(questionToUpdate);
    if (mismatchAnswerObject[0]) {
      this._toastrService.error(
        messages.ERROR_MESSAGE.ANSWERS_OBJECT_MISMATCH_PART_ONE +
          `${mismatchAnswerObject[1]}` +
          messages.ERROR_MESSAGE.ANSWERS_OBJECT_MISMATCH_PART_TWO
      );
      return;
    }
    this._questionLibraryService
      .updateQuestion(this.questionData.qualification_code, questionToUpdate)
      .subscribe(
        (response) => {
          this._toastrService.success(
            notifyMessage.successMessage.QUESTION_LIBRARY.QUESTION_UPDATED
          );
        },
        (error) => {
          this._displayErrorMessage(error);
        }
      );
  }

  private _validateLocalization(questionToUpdate: {
    localizations: Localization[];
  }) {
    let differenceInAnswersArray = false;
    let locale = questionToUpdate.localizations;
    const localeBasedData = locale.reduce(
      (previousValue: ModifiedDataInterface, currentValue: Localization) => {
        if (
          !Object.keys(previousValue).includes(
            currentValue.locale.split('_')[1]
          )
        ) {
          previousValue[currentValue.locale.split('_')[1]] = {
            data: [currentValue.answers],
            locale: currentValue.locale,
          };
        } else {
          previousValue[currentValue.locale.split('_')[1]].data.push(
            currentValue.answers
          );
        }
        return previousValue;
      },
      {}
    );

    let differentAnswerArray = [];
    let misMatchedAnswerLocale: string = '';
    for (const [key, value] of Object.entries<LocaleBasedAnswersArrayInterface>(
      localeBasedData
    )) {
      const localeBasedAnswersArray = value;
      let localeBasedAnswersArrayData = localeBasedAnswersArray.data;
      if (localeBasedAnswersArrayData.length > 1) {
        for (let i = 0; i < localeBasedAnswersArrayData.length; i++) {
          const arrayOne = localeBasedAnswersArrayData[i];
          const arrayTwo = localeBasedAnswersArrayData[i + 1];
          if (arrayTwo) {
            if (arrayTwo.length !== arrayOne.length) {
              differenceInAnswersArray = true;
              misMatchedAnswerLocale = localeBasedAnswersArray.locale;
            }
            differentAnswerArray = arrayOne.filter(
              ({ condition_code: id1 }: { condition_code: number }) =>
                !arrayTwo.some(
                  ({ condition_code: id2 }: { condition_code: number }) =>
                    id2 === id1
                )
            );
          }
          if (differentAnswerArray.length) {
            differenceInAnswersArray = true;
            misMatchedAnswerLocale = localeBasedAnswersArray.locale;
          }
        }
      }
    }
    return [differenceInAnswersArray, misMatchedAnswerLocale];
  }

  private _patchFormDetails() {
    if (!this.questionData || !Object.keys(this.questionData).length) {
      return;
    }

    if (this.userType === 'buyer' && this._userCmp) {
      this.questionData.cmp = [this._userCmp];
    }

    const questionTypes = [...Constants.QUESTION_TYPE_OPTIONS];
    this.selectedCompanies = this._patchCompany();
    this.form.patchValue({
      company: this.selectedCompanies,
      class: {
        key: this.questionData.class,
        value: this._getNameById(
          this.classValues,
          this.questionData.class,
          'key',
          'value',
          'Extended'
        ),
      },
      recency: this.questionData.recency,
      desc: this.questionData.desc,
      category: this.questionData.category,
      type: {
        key: this.questionData.type,
        value: this._getNameById(
          questionTypes,
          this.questionData.type,
          'key',
          'value',
          'Single Punch'
        ),
      },
      stem1: this.questionData.stem1 || '',
      stem2: this.questionData.stem2 || '',
      hideQuestion: this.questionData.is_hidden || false,
      buyertext: this.questionData.buyer_text || '',
      create_survey_question_order:
        this.questionData.create_survey_question_order || '',
      tags: this._prepareTagsForFormPatch(this.questionData.tags),
      randomOrder: {
        key: this.questionData.randomize_answer_options,
        value: this.questionData.randomize_answer_options ? 'On' : 'Off',
      },
      units:
        this.rangeUnits.find((r) => r.key === this.questionData.units) || '',
      answerOrder: {
        key: this.questionData.answer_order,
        value: this._getNameById(
          this.answerOrder,
          this.questionData.answer_order,
          'key',
          'value',
          'Alpha/Numeric'
        ),
      },
    });
    this.localizations = this.questionData.localizations;
    this._manageDisablingButtons();
    this.isPrivacyPolicyQuestion =
      this.questionData.qualification_code ===
      Constants.SPECIAL_QUESTION_IDS.PRIVACY_POLICY_QUAL_ID;
  }

  private _getNameById(
    arr: any[],
    idToMatch: number,
    keyToCheck: string,
    keyToReturn: string,
    defaultVal: string
  ) {
    const foundItem = (arr || []).find((val) => val[keyToCheck] === idToMatch);
    if (!foundItem) {
      return defaultVal;
    }
    return foundItem[keyToReturn];
  }

  private _manageDisablingButtons() {
    //Decide if editing is disabled for some form fields
    this.isEditingDisabled = this._isEditingDisabled();
    this.isSegmentation = this._isSegmentation();
    // Set stem1, stem2, buyer text button disabled
    ['stem1', 'stem2', 'buyertext'].forEach((item) => {
      this.checkAndSetButtonDisabled(item);
    });
  }

  private _isSegmentation() {
    return this.questionData.class === Constants.MAPPED_CLASS_TYPE.SEGMENTATION;
  }

  private _isEditingDisabled() {
    this.isUserHasQuestionLibraryAccess =
      !!this._authService.user?.questionLibraryAccessLevel;
    return (
      this.isEditMode &&
      (this.questionData.class === Constants.MAPPED_CLASS_TYPE.CORE ||
        (this.userType === 'buyer' &&
          (!this._userCmp || !this.questionData.cmp.includes(this._userCmp)) &&
          this.questionData.class !== Constants.MAPPED_CLASS_TYPE.CUSTOM) ||
        this.questionData.category.some((obj) => obj.label === 'Read Only'))
    );
  }

  private _canAddCategory() {
    this.isUserHasQuestionLibraryAccess =
      !!this._authService.user?.questionLibraryAccessLevel;
    return this.userType === 'buyer'
      ? false
      : this.isUserHasQuestionLibraryAccess;
  }

  private _prepareTagsForFormPatch(tags: string = '') {
    return !!tags
      ? tags.split(',').map((tagLabel) => {
          return { name: tagLabel, label: tagLabel };
        })
      : '';
  }

  public async confirmAndDeleteQuestion() {
    const isProceed = await this._openModalAndConfirmResponse(
      Constants.MODAL.DELETE_QUESTION.TEXT
    );
    if (!isProceed) {
      return;
    }
    this._deleteQuestion();
  }

  private _openModalAndConfirmResponse(modalText: string) {
    const modalRef = this._modal.open(ConfirmationModalComponent, {
      data: {
        text: modalText,
        confirmButtonLabel: 'OK',
      },
      width: '450px',
    });
    return modalRef.onClose$.pipe(map((msg) => msg === 'ok')).toPromise();
  }

  private _deleteQuestion() {
    this._questionLibraryService
      .deleteQuestion(this.questionData.qualification_code)
      .subscribe(
        (response) => {
          this.formModeChange.emit(false);
          this.reloadQuestions.emit(true);
          this._toastrService.success(
            notifyMessage.successMessage.QUESTION_LIBRARY.QUESTION_DELETED
          );
        },
        (error) => {
          this._displayErrorMessage(error);
        }
      );
  }

  private _deleteQuestionLocalization(localization: string = '') {
    this._questionLibraryService
      .deleteQuestion(this.questionData.qualification_code, localization)
      .subscribe(
        (response) => {
          this._toastrService.success(
            notifyMessage.successMessage.QUESTION_LIBRARY.LOCALIZATION_DELETED
          );
        },
        (error) => {
          this._displayErrorMessage(error);
        }
      );
  }

  public cloneLocalization(index: number, e: Event) {
    const upcomingValue = !this.isCloneLocalization;
    if (upcomingValue) {
      this.clonedLocalization = {
        ...this.localizations[index],
        locale: '',
      };
    } else {
      this.clonedLocalization = {} as Localization;
    }
    this.isCloneLocalization = !this.isCloneLocalization;
    e.stopPropagation();
  }

  public newLocalizationToggle() {
    this.isCloneLocalization = false;
    this.clonedLocalization = {} as Localization;
  }

  public copyQuestionText(index: number, e: Event) {
    const copiedText = this._getQuestionTextToCopy(this.localizations[index]);
    navigator.clipboard
      .writeText(copiedText)
      .then(() => {
        this._toastrService.success(
          notifyMessage.successMessage.QUESTION_LIBRARY.QUESTION_TEXT_COPIED
        );
      })
      .catch((err) => {
        this._toastrService.error(
          notifyMessage.errorMessage.QUESTION_LIBRARY.QUESTION_TEXT_COPY
        );
      });
    e.stopPropagation();
  }

  private _getQuestionTextToCopy(localization: Localization) {
    let answersText = '';
    const regEx = new RegExp(
      `%%${this.questionData.qualification_code}%%`,
      'g'
    );
    if (localization.answers.length) {
      const sortedAnswers = localization.answers.sort(
        (a, b) => a.condition_code - b.condition_code
      );
      answersText = `${sortedAnswers
        .map(
          (answer) =>
            `${answer.condition_code} - ${answer.eng_text} ${
              !localization.locale.includes('en')
                ? `- ${answer.local_text}`
                : ''
            }`
        )
        .join('\n')}`;
    }
    return `${this.questionData.qualification_code} | ${
      this.questionData.desc
    } {${localization.locale.replace(
      '_',
      ' - '
    )}}\n\n${localization.text.replace(regEx, '')}\n\n${answersText}`;
  }

  // Helps get unique identifier for each localization, so that its state can be maintained (using trackBy)
  public getLocalizationId(i: number, item: Localization) {
    return `${item.locale}_${item.text}_${i}`;
  }

  private _getCategories() {
    this._questionLibraryService.getCategories().subscribe(
      (categories: Category[]) => {
        this.categories = categories.filter((category) => category !== null);
      },
      (error) => {
        this._displayErrorMessage(error);
      }
    );
  }

  public selectCategory(categories: Category[]) {
    this.form.patchValue({
      category: categories,
    });
    if (this.canUserAddCategory) {
      this._saveCategory();
    }
  }

  private _saveCategory() {
    const categoriesPayload: CategoryPayload[] = this._mapSaveTagsPayload();
    if (categoriesPayload.length) {
      this._questionLibraryService.saveCategories(categoriesPayload).subscribe(
        (categories: Category[]) => {
          this.form.patchValue({
            category: this.form.value.category.map((category: Category) => ({
              ...category,
              id: !category.id
                ? categories.find(
                    (c) => c.label.trim() === category.label.trim()
                  )?.id || null
                : category.id,
            })),
          });
          this.categories = categories;
        },
        (error) => {
          this._displayErrorMessage(error);
        }
      );
    }
  }
  private _mapSaveTagsPayload() {
    const categories = this.form.value.category;
    const categoriesPayload: CategoryPayload[] = [];
    if (categories.length) {
      categories.forEach((category: Category) => {
        if (typeof category === 'object' && !category.id) {
          categoriesPayload.push({ label: category.label.trim() });
        } else if (typeof category === 'string') {
          categoriesPayload.push({ label: (category as string).trim() });
        }
      });
    }
    return categoriesPayload;
  }
}
