import { DashboardPreference } from 'src/app/shared/services/buyer-api/survey.interface';
import { UserType } from 'src/app/shared/types';
import { Widget } from '../widget/widget';
import { CompanyInfo } from '../../shared/interfaces/company.interface';

type DashboardConfig = {
  dynamic: boolean;
  trafficChannels: boolean;
  healthCheck: boolean;
  multiCountry: boolean;
  enablePaceTool: boolean;
};

type DashboardPreferenceParams = {
  columns?: DashboardPreference[];
  widgets?: Widget[];
  mobile?: boolean;
  user?: UserType;
  configs?: DashboardConfig;
  serviceOperator?: boolean;
  enableBulkEditAndSorting?: boolean;
};

export class DashboardPreferenceState {
  constructor(
    public readonly columns: DashboardPreference[],
    public readonly widgets: Widget[],
    public readonly mobile: boolean = false,
    public readonly user: UserType = 'buyer',
    public readonly configs: DashboardConfig = {
      dynamic: false,
      trafficChannels: false,
      healthCheck: false,
      multiCountry: false,
      enablePaceTool: false,
    },
    public readonly serviceOperator: boolean = false,
    public readonly enableBulkEditAndSorting: boolean = false,
    public readonly company?: CompanyInfo
  ) {}

  public static none(): DashboardPreferenceState {
    return new DashboardPreferenceState([], []);
  }

  public static from(
    columns: DashboardPreference[],
    mobile: boolean,
    userType: UserType,
    dynamic: boolean,
    trafficChannels: boolean,
    service: boolean,
    enableBulkEditAndSorting: boolean,
    multiCountry: boolean,
    healthCheck: boolean,
    enablePaceTool: boolean,
    company?: CompanyInfo
  ): DashboardPreferenceState {
    if (userType === 'supplier') {
      return new DashboardPreferenceState(
        columns,
        [],
        mobile,
        userType,
        { dynamic, trafficChannels, healthCheck, multiCountry, enablePaceTool },
        service
      );
    }

    const options = columns.filter((column) => {
      return (
        column.selected &&
        ((mobile && column.mobile) || (!mobile && column.desktop))
      );
    });

    const filtered = options.filter((option) => {
      if (userType === 'buyer') {
        if (option.key === 'trafficChannels') {
          return trafficChannels;
        }
        if (option.key === 'multiCountry') {
          return multiCountry;
        }
        if (option.key === 'healthCheck') {
          return healthCheck;
        }
        if (option.key === 'pacing') {
          return enablePaceTool;
        }
      }
      return true;
    });

    return new DashboardPreferenceState(
      filtered,
      Widget.extract(columns, mobile),
      mobile,
      userType,
      { dynamic, trafficChannels, healthCheck, multiCountry, enablePaceTool },
      service,
      enableBulkEditAndSorting,
      company
    );
  }

  public widget(key: string): Widget {
    const finded = this.widgets.find((widget) => widget.key === key);

    if (finded) {
      return finded;
    }

    throw new Error(`No widget found for key ${key}`);
  }

  public copyWith({
    columns,
    widgets,
    mobile,
    user,
    configs,
    serviceOperator,
    enableBulkEditAndSorting,
  }: DashboardPreferenceParams): DashboardPreferenceState {
    return new DashboardPreferenceState(
      columns ?? this.columns,
      widgets ?? this.widgets,
      mobile ?? this.mobile,
      user ?? this.user,
      configs ?? this.configs,
      serviceOperator ?? this.serviceOperator,
      enableBulkEditAndSorting ?? this.enableBulkEditAndSorting
    );
  }
}
