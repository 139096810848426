<div class="supplier-tabs" [formGroup]="form">
  <div class="container form-group" formGroupName="variable_mapping">
    <div class="row form-group" formGroupName="ps_supplier_sid">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <label class="control-label">Incoming</label>
      </div>
      <div class="col-md-4">
        <label class="control-label">Outgoing</label>
      </div>
    </div>

    <div class="row form-group" formGroupName="ps_supplier_sid">
      <div class="col-md-4">
        <label>Unique Session ID</label>
      </div>
      <div class="col-md-4">
        <input
          name="ps_supplier_sid"
          placeholder="ps_supplier_sid"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get(
              'variable_mapping.ps_supplier_sid.incoming'
            )?.valid
          }"
          formControlName="incoming"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_supplier_sid.incoming')?.errors
              ?.maxlength
          "
        >
          Exceeded maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_supplier_sid.incoming')?.errors
              ?.pattern
          "
        >
          It must contain only letters, digits, and underscores.
        </div>
      </div>
      <div class="col-md-4">
        <input
          name="ps_supplier_sid"
          placeholder="ps_supplier_sid"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get(
              'variable_mapping.ps_supplier_sid.outgoing'
            )?.valid
          }"
          formControlName="outgoing"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_supplier_sid.outgoing')?.errors
              ?.maxlength
          "
        >
          Exceeded maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_supplier_sid.outgoing')?.errors
              ?.pattern
          "
        >
          It must contain only letters, digits, and underscores.
        </div>
      </div>
    </div>

    <div class="row form-group" formGroupName="ps_supplier_respondent_id">
      <div class="col-md-4">
        <label>Unique Member / Panelist ID</label>
      </div>
      <div class="col-md-4">
        <input
          name="ps_supplier_respondent_id"
          placeholder="ps_supplier_respondent_id"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get(
              'variable_mapping.ps_supplier_respondent_id.incoming'
            )?.valid
          }"
          formControlName="incoming"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_supplier_respondent_id.incoming')
              ?.errors?.maxlength
          "
        >
          Exceeded maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_supplier_respondent_id.incoming')
              ?.errors?.pattern
          "
        >
          It must contain only letters, digits, and underscores.
        </div>
      </div>
      <div class="col-md-4">
        <input
          name="ps_supplier_respondent_id"
          placeholder="ps_supplier_respondent_id"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get(
              'variable_mapping.ps_supplier_respondent_id.outgoing'
            )?.valid
          }"
          formControlName="outgoing"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_supplier_respondent_id.outgoing')
              ?.errors?.maxlength
          "
        >
          Exceeded maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_supplier_respondent_id.outgoing')
              ?.errors?.pattern
          "
        >
          It must contain only letters, digits, and underscores.
        </div>
      </div>
    </div>

    <div class="row form-group" formGroupName="ps_custom_svar1">
      <div class="col-sm">
        <label>Custom Variable 1</label>
      </div>
      <div class="col-sm">
        <input
          name="ps_custom_svar1"
          placeholder="ps_custom_svar1"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get(
              'variable_mapping.ps_custom_svar1.incoming'
            )?.valid
          }"
          formControlName="incoming"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_custom_svar1.incoming')?.errors
              ?.maxlength
          "
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_custom_svar1.incoming')?.errors
              ?.pattern
          "
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
      <div class="col-sm">
        <input
          name="ps_custom_svar1"
          placeholder="ps_custom_svar1"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get(
              'variable_mapping.ps_custom_svar1.outgoing'
            )?.valid
          }"
          formControlName="outgoing"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_custom_svar1.outgoing')?.errors
              ?.maxlength
          "
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_custom_svar1.outgoing')?.errors
              ?.pattern
          "
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
    </div>

    <div class="row form-group" formGroupName="ps_custom_svar2">
      <div class="col-sm">
        <label>Custom Variable 2</label>
      </div>
      <div class="col-sm">
        <input
          name="ps_custom_svar2"
          placeholder="ps_custom_svar2"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get(
              'variable_mapping.ps_custom_svar2.incoming'
            )?.valid
          }"
          formControlName="incoming"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_custom_svar2.incoming')?.errors
              ?.maxlength
          "
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_custom_svar2.incoming')?.errors
              ?.pattern
          "
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
      <div class="col-sm">
        <input
          name="ps_custom_svar2"
          placeholder="ps_custom_svar2"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get(
              'variable_mapping.ps_custom_svar2.outgoing'
            )?.valid
          }"
          formControlName="outgoing"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_custom_svar2.outgoing')?.errors
              ?.maxlength
          "
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_custom_svar2.outgoing')?.errors
              ?.pattern
          "
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm">
        <label>Purespectrum Termination Reason</label>
      </div>
      <div class="col-md"></div>
      <div class="col-md" formGroupName="ps_term_rsn">
        <input
          name="ps_term_rsn"
          placeholder="ps_term_rsn"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get(
              'variable_mapping.ps_term_rsn.outgoing'
            )?.valid
          }"
          formControlName="outgoing"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_term_rsn.outgoing')?.errors
              ?.maxlength
          "
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_term_rsn.outgoing')?.errors
              ?.pattern
          "
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm">
        <label>Purespectrum Respondent ID</label>
      </div>
      <div class="col-sm"></div>
      <div class="col-sm">
        <input
          name="ps_psid"
          placeholder="ps_psid"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get('variable_mapping.ps_psid')?.valid
          }"
          formControlName="ps_psid"
          [readonly]="true"
        />
        <div
          class="invalid-feedback"
          *ngIf="this.form.get('variable_mapping.ps_psid')?.errors?.maxlength"
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="this.form.get('variable_mapping.ps_psid')?.errors?.pattern"
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm">
        <label>Purespectrum Respondent Status Code</label>
      </div>
      <div class="col-sm"></div>
      <div class="col-sm" formGroupName="ps_rstatus">
        <input
          name="ps_rstatus"
          placeholder="ps_rstatus"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get('variable_mapping.ps_rstatus.outgoing')
              ?.valid
          }"
          formControlName="outgoing"
          [readonly]="true"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_rstatus.outgoing')?.errors
              ?.maxlength
          "
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_rstatus.outgoing')?.errors
              ?.pattern
          "
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
    </div>

    <div class="row form-group" formGroupName="ps_hash">
      <div class="col-sm">
        <label>Purespectrum Hash Code</label>
      </div>
      <div class="col-sm">
        <input
          name="ps_hash"
          placeholder="ps_hash"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get('variable_mapping.ps_hash.incoming')
              ?.valid
          }"
          formControlName="incoming"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_hash.incoming')?.errors
              ?.maxlength
          "
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_hash.incoming')?.errors?.pattern
          "
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
      <div class="col-sm">
        <input
          name="ps_hash"
          placeholder="ps_hash"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get('variable_mapping.ps_hash.outgoing')
              ?.valid
          }"
          formControlName="outgoing"
          [readonly]="true"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_hash.outgoing')?.errors
              ?.maxlength
          "
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_hash.outgoing')?.errors?.pattern
          "
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm">
        <label>Purespectrum Quota ID for OQ</label>
      </div>
      <div class="col-md"></div>
      <div class="col-md" formGroupName="ps_oqid">
        <input
          name="ps_oqid"
          placeholder="ps_oqid"
          type="text"
          class="form-control input-md"
          [ngClass]="{
            'is-invalid': !this.form.get('variable_mapping.ps_oqid.outgoing')
              ?.valid
          }"
          formControlName="outgoing"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_oqid.outgoing')?.errors
              ?.maxlength
          "
        >
          Key name exceeds maximum number of characters (36).
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            this.form.get('variable_mapping.ps_oqid.outgoing')?.errors?.pattern
          "
        >
          Variable name must contain only letters, digits, and underscores.
        </div>
      </div>
    </div>
  </div>
</div>
