<div class="form-inline">
  <div class="row">
    <div class="col-md-1">
      <label class="pull-left" style="margin-right: 15px">Buyer</label>
    </div>
    <div class="col-sm-6 col-md-4 form-group mb-35">
      <ps-ui-select-dropdown
        [dropdownItems]="buyers"
        [field]="'name'"
        [enableSearch]="true"
        labelNone="Select Buyer"
        [label]="selectedBuyer?.name"
        (selectedItem)="selectBuyer($event)"
      >
      </ps-ui-select-dropdown>
    </div>
  </div>

  <div class="well" *ngIf="selectedBuyer">
    <div class="col-xs-12 flex-align buyer-header">
      <h4 class="text-uppercase text-center mar35">
        {{ selectedBuyer.name }}
      </h4>
      <div class="setting-info">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <p style="white-space: normal">
          To learn more about some of the sections and their respective sub
          settings
          <a [href]="CONFIG_FEATURES_DOCUMENTATION_LINK" target="_blank"
            >CLICK HERE</a
          >
        </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-4 col-md-offset-4 row-width">
        <div class="flex-row">
          <div class="acordion-container">
            <ps-ui-accordion
              class="acordion"
              *ngFor="let section of configFields; index as i"
            >
              <ps-ui-accordion-section
                *ngIf="i !== blockedCountriesSectionIndex"
                [title]="section.section"
              >
                <div class="col-xs-3" *ngFor="let config of section.features">
                  <div
                    class="softlaunch-toggle-btn config-tab"
                    *ngIf="!config.parent && !config.hide"
                  >
                    <label class="config-name-label">{{ config.name }}</label>
                    <div class="setting-info">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      <p
                        style="white-space: normal"
                        [innerHTML]="config.desc | safeHtml"
                      ></p>
                    </div>
                    <label class="status pull-right">
                      <ps-ui-slider
                        (selectedChange)="
                          updateConfigSettings(
                            config.value,
                            config.hasAdditionalInfo
                          )
                        "
                        [selected]="selectedBuyerConfig[config.value]"
                        [disabled]="config.disabled"
                      ></ps-ui-slider>
                    </label>
                    <div
                      *ngIf="
                        isParent(config.value) &&
                        selectedBuyerConfig[config.value]
                      "
                    >
                      <div *ngFor="let configWithParent of section.features">
                        <div
                          class="softlaunch-toggle-btn config-tab"
                          *ngIf="
                            !configWithParent.hide &&
                            configWithParent.parent &&
                            configWithParent.parentValue === config.value
                          "
                        >
                          <label class="child-config-name-label">{{
                            configWithParent.name
                          }}</label>
                          <div class="setting-info">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <p style="white-space: normal">
                              {{ configWithParent.desc }}
                            </p>
                          </div>
                          <label class="status pull-right">
                            <ps-ui-slider
                              (selectedChange)="
                                updateConfigSettings(configWithParent.value)
                              "
                              [selected]="
                                selectedBuyerConfig[configWithParent.value]
                              "
                              [disabled]="configWithParent.disabled"
                            ></ps-ui-slider>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ps-ui-accordion-section>
            </ps-ui-accordion>
          </div>
          <ps-ui-accordion
            class="acordion"
            *ngFor="
              let section of configFields
                | configSettingsPipe : { section: 'CUSTOM COUNTRIES' }
            "
          >
            <ps-ui-accordion-section
              class="accordion-section"
              [title]="section.section"
            >
              <div
                class="softlaunch-toggle-btn config-tab"
                *ngFor="let config of section.features"
              >
                <label class="config-name-label">{{ config.name }}</label>
                <div class="setting-info">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                  <p
                    style="white-space: normal"
                    [innerHTML]="config.desc | safeHtml"
                  ></p>
                </div>
                <label class="status pull-right">
                  <ps-ui-slider
                    (selectedChange)="
                      updateConfigSettings(
                        config.value,
                        config.hasAdditionalInfo
                      )
                    "
                    [selected]="selectedBuyerConfig[config.value]"
                    [disabled]="config.disabled"
                  ></ps-ui-slider>
                </label>
              </div>
              <hr />
              <label>Default Country</label>
              <ps-ui-select-dropdown
                [dropdownItems]="permittedCountries"
                [label]="defaultCountry?.name"
                [field]="'name'"
                [clearSearch]="true"
                labelNone="Select Country"
                labelAlignment="start"
                (selectedItem)="selectDefaultCountry($event)"
              >
              </ps-ui-select-dropdown>
              <hr />
              <div>
                <label>Blocked Countries</label>
                <ps-ui-select-dropdown
                  [multi]="true"
                  [dropdownItems]="countries"
                  [field]="'name'"
                  [clearSearch]="false"
                  class="uppercase"
                  labelNone="Select Country"
                  labelAlignment="start"
                  [(ngModel)]="blockedCountries"
                  (selectedItems)="setBlockedCountries($event)"
                  [sortBySelected]="true"
                  [orderBy]="'orderId'"
                >
                </ps-ui-select-dropdown>
              </div>
            </ps-ui-accordion-section>
          </ps-ui-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
