import { Component, OnDestroy, OnInit } from '@angular/core';
import { IndividualConfig } from 'ngx-toastr';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';

import { CommonService } from '../../shared/services/common-service/common-service';
import { EachCountry } from '../../shared/interfaces/common-service.interface';
import { CensusService } from '../../shared/services/buyer/census.service';
import { Constants } from '../operator-settings-constants';
import { notifyMessage } from '../../constants/notify-message';
import { QUALIFICATION_CODES } from '../../constants/qualification-code';
import {
  QualificationsResponse,
  CensusResponse,
  Qualification,
  ConditionCodeCensus,
  Answer,
} from '../operator-settings.interface';
import { deepCopy } from '../../utils/deep-copy';

@Component({
  selector: 'ps-manage-census',
  templateUrl: './manage-census.component.html',
  styleUrls: ['./manage-census.component.css'],
})
export class ManageCensusComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public countries: EachCountry[] = [];
  public selectedCountry!: EachCountry;
  public selectedQualification!: Qualification;
  public censusQualifications: CensusResponse[] = [];
  public locale: string = '';
  public newMin: string = '';
  public newMax: string = '';
  public newRepValue: string = '';
  constructor(
    private _toastr: ToasterService,
    private _commonService: CommonService,
    private _censusService: CensusService
  ) {}

  ngOnInit(): void {
    this._getCountries();
  }

  private _getCountries() {
    const countriesResponse$ = this._commonService.getCountries().subscribe(
      (resp) => {
        this.countries = resp;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add(countriesResponse$);
  }

  selectCountry(country: EachCountry) {
    this.selectedCountry = country;
    this.selectedQualification = <Qualification>{};
    this._getQualifications();
  }
  private _getQualificationsCensusService(qualifications: string) {
    this._censusService
      .getQualifications(this.locale, qualifications)
      .subscribe(
        (respt) => {
          this._mapQualifications(respt);
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
  }
  private _getQualifications() {
    const selectedLanguage = this.selectedCountry.lang[0];
    this.locale = `${selectedLanguage.transalte_code}_${this.selectedCountry.short_Code}`;
    let qualificationIds = Constants.CENSUS_QUAL_IDS;

    //Hide Legacy regions for countries
    if (
      Object.keys(Constants.LEGACY_REGIONS_TO_HIDE).includes(
        this.selectedCountry.short_Code
      )
    ) {
      qualificationIds = Constants.CENSUS_QUAL_IDS.filter(
        (qId) =>
          !Constants.LEGACY_REGIONS_TO_HIDE[
            this.selectedCountry
              .short_Code as keyof typeof Constants.LEGACY_REGIONS_TO_HIDE
          ].includes(qId)
      );
    }

    const response = this._censusService
      .getQualificationsWithCensusData(this.selectedCountry.short_Code)
      .subscribe(
        (resp) => {
          if (resp.length) {
            qualificationIds.push(...resp);
          }
          this._getQualificationsCensusService(qualificationIds.toString());
        },
        (error) => {
          this._toastr.error(error);
        }
      );
    this._subscriptions.add(response);
  }

  private _mapQualifications(allQualifications: Array<QualificationsResponse>) {
    this.censusQualifications = [];
    allQualifications.forEach((qualification) => {
      const qual = {
        qualification_code: qualification.ps_qualification_code,
        condition_codes: Array(),
        desc: qualification.desc,
      };
      if (
        qualification.localizations &&
        qualification.localizations.length > 0
      ) {
        if (
          qualification.localizations[0].answers &&
          qualification.localizations[0].answers.length > 0
        ) {
          qualification.localizations[0].answers.forEach(
            (condition: Answer) => {
              qual.condition_codes.push({
                name: condition.text,
                condition_code: condition.ps_cond_code,
                rep_value: '',
              });
            }
          );
        } else {
          qual.condition_codes.push({
            min: qualification.localizations[0].format.min,
            max: qualification.localizations[0].format.max,
            condition_code: 1,
            rep_value: '',
          });
        }

        this.censusQualifications.push(qual);
      }
    });
  }

  getCensusDetails(qualification: Qualification) {
    this.selectedQualification = deepCopy(qualification);
    const censusResponse$ = this._censusService
      .getCensus(this.locale, this.selectedQualification.qualification_code)
      .subscribe(
        (resp) => {
          if (resp) {
            resp.condition_codes.push(
              ...this.selectedQualification.condition_codes.filter(
                (selectedCondition) =>
                  !resp.condition_codes.some(
                    (condition) =>
                      selectedCondition.condition_code ===
                      condition.condition_code
                  )
              )
            );
            this.selectedQualification.condition_codes = resp.condition_codes;
          }
        },
        (error) => {
          if (error.error.msg !== Constants.NO_QUALIFICATION_ERROR_MESSAGE) {
            this._toastr.error(error.error.msg);
          }
        }
      );
    this._subscriptions.add(censusResponse$);
  }

  isRangeType(qualificationCode: number) {
    return [QUALIFICATION_CODES.AGE, QUALIFICATION_CODES.INCOME].indexOf(
      qualificationCode
    ) === -1
      ? false
      : true;
  }

  addCensus() {
    if (this._vaildateNewCensusData()) {
      const newCensusData = {
        min: parseInt(this.newMin),
        max: parseInt(this.newMax),
        rep_value: parseInt(this.newRepValue),
      };
      this.selectedQualification.condition_codes.push(newCensusData);

      this.newMin = '';
      this.newMax = '';
      this.newRepValue = '';
    }
  }

  removeCensus(index: number) {
    this.selectedQualification.condition_codes.splice(index, 1);
  }

  saveCensus() {
    if (this._vaildateCensusData() && this._checkRangeOverlap()) {
      this.selectedQualification.condition_codes =
        this.selectedQualification.condition_codes.map(
          (conditionCode: ConditionCodeCensus) => {
            if (
              conditionCode.hasOwnProperty('min') &&
              conditionCode.hasOwnProperty('max')
            ) {
              conditionCode.min = Number(conditionCode.min);
              conditionCode.max = Number(conditionCode.max);
              if (conditionCode.hasOwnProperty('condition_code')) {
                delete conditionCode.condition_code;
              }
              return conditionCode;
            } else {
              return conditionCode;
            }
          }
        );

      this.selectedQualification.locale = this.locale;
      this._censusService
        .saveCensus(Constants.CENSUS_REP_ID, this.selectedQualification)
        .subscribe(
          (resp) => {
            this._toastr.success(
              notifyMessage.successMessage.SUCCESSFULL_SAVED,
              'Success',
              {
                timeOut: 2000,
              } as IndividualConfig
            );
          },
          (error) => {
            this._toastr.error(error.error.msg);
          }
        );
    }
  }

  private _vaildateNewCensusData() {
    if (!this.newMin || !this.newMax || !this.newRepValue) {
      this._toastr.warning(notifyMessage.warningMessage.COMPLETE_REQUIRED);
      return false;
    }

    if (parseInt(this.newMin) >= parseInt(this.newMax)) {
      this._toastr.warning(
        notifyMessage.warningMessage.INVALID_RANGE.MIN_GREATER_THAN_MAX
      );
      return false;
    }

    const invalidMin = this.selectedQualification.condition_codes.some(
      (conditionCode: ConditionCodeCensus) => {
        if (
          Number(this.newMin) >= Number(conditionCode.min) &&
          Number(this.newMin) <= Number(conditionCode.max)
        ) {
          return true;
        } else {
          return false;
        }
      }
    );

    if (invalidMin) {
      this._toastr.warning(
        notifyMessage.warningMessage.INVALID_RANGE.OVERLAPPING
      );
      return false;
    }

    const invalidMax = this.selectedQualification.condition_codes.some(
      (conditionCode: ConditionCodeCensus) => {
        if (
          Number(this.newMin) >= Number(conditionCode.min) &&
          Number(this.newMax) <= Number(conditionCode.max)
        ) {
          return true;
        } else {
          return false;
        }
      }
    );

    if (invalidMin) {
      this._toastr.warning(
        notifyMessage.warningMessage.INVALID_RANGE.OVERLAPPING_TO
      );
      return false;
    }

    if (
      this.isRangeType(this.selectedQualification.qualification_code) &&
      !this._checkRangeOverlap()
    ) {
      this._toastr.warning(
        notifyMessage.warningMessage.INVALID_RANGE.OVERLAPPING
      );
      return false;
    }

    return true;
  }

  private _checkRangeOverlap() {
    const ranges = this.selectedQualification.condition_codes;
    const n = ranges.length;

    for (let i = 0; i < n; i++) {
      for (let u = 0; u < n; u++) {
        if (i == u) {
          continue;
        }

        let rangeA = ranges[i];
        let rangeB = ranges[u];
        if (
          (Number(rangeA.min) >= Number(rangeB.min) &&
            Number(rangeA.min) <= Number(rangeB.max)) ||
          (Number(rangeA.max) >= Number(rangeB.min) &&
            Number(rangeA.max) <= Number(rangeB.max))
        ) {
          // if the ranges intersect
          this._toastr.warning(
            notifyMessage.warningMessage.INVALID_RANGE.OVERLAPPING
          );
          return false;
        }
      }
    }
    return true; // no intersections found
  }

  private _vaildateCensusData() {
    const blankPer = this.selectedQualification.condition_codes.some(
      (conditionCode: ConditionCodeCensus) => {
        if (!conditionCode.rep_value && conditionCode.rep_value !== 0) {
          return true;
        } else {
          return false;
        }
      }
    );

    if (blankPer) {
      this._toastr.warning(
        notifyMessage.warningMessage.CENSUS.COMPLETE_REQUIRED
      );
      return false;
    }

    const invalidPer = this.selectedQualification.condition_codes.some(
      (conditionCode: ConditionCodeCensus) => {
        if (conditionCode.rep_value > 100) {
          return true;
        } else {
          return false;
        }
      }
    );

    if (invalidPer) {
      this._toastr.warning(notifyMessage.warningMessage.CENSUS.INCORRECT);
      return false;
    }

    const sum = this.selectedQualification.condition_codes.reduce(
      (acc: number, conditionCode: ConditionCodeCensus) => {
        const num =
          conditionCode.rep_value && !isNaN(conditionCode.rep_value)
            ? conditionCode.rep_value
            : 0;
        return acc + num;
      },
      0
    );

    if (sum !== 100) {
      this._toastr.warning(notifyMessage.warningMessage.CENSUS.INCORRECT);
      return false;
    }

    return true;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
