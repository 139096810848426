import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupplierSettingsService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getManageSupplierSettings(supplierId: number): Observable<SupplierSettings> {
    return this._http
      .get<ManageSupplierSettingsResponse>(
        `${this._baseUrl}/supplierSettings/${supplierId}`
      )
      .pipe(
        map((response) => {
          const supplier = this._convertVariableMappingResponse(
            response.supplier[0]
          );

          return supplier;
        })
      );
  }
  updateManageSupplierSettings(
    updateObj: SupplierSettings,
    supplierId: number
  ) {
    const payload = this._convertVariableMappingPayload(updateObj);

    return this._http.put(
      `${this._baseUrl}/supplierSettings/${supplierId}`,
      payload
    );
  }

  /**
   * Flatten the variable mappings into an object to make modifications easier
   * @param variable_mapping list of mapped variables
   * @returns list of mapped variables as a single object
   */
  private _convertVariableMappingResponse(
    supplier: SupplierSettingsBackend
  ): SupplierSettings {
    // Initialize the mapped variables values
    const variablesObj: VariableMapping = {
      ps_supplier_sid: {
        incoming: 'ps_supplier_sid',
        outgoing: 'ps_supplier_sid',
      },
      ps_supplier_respondent_id: {
        incoming: 'ps_supplier_respondent_id',
        outgoing: 'ps_supplier_respondent_id',
      },
      ps_custom_svar1: {
        incoming: 'ps_custom_svar1',
        outgoing: 'ps_custom_svar1',
      },
      ps_custom_svar2: {
        incoming: 'ps_custom_svar2',
        outgoing: 'ps_custom_svar2',
      },
      ps_term_rsn: { outgoing: '' },
      ps_oqid: { outgoing: '' },
    };

    const { variable_mapping, ...convertedSupplier } = supplier;

    if (!variable_mapping) {
      return convertedSupplier;
    }

    for (let i = 0; i < variable_mapping.length; i++) {
      for (let varName in variable_mapping[i]) {
        let varObj = {};

        varObj = {
          [varName]: {
            incoming: variable_mapping[i][varName]?.incoming,
            outgoing: variable_mapping[i][varName].outgoing,
          },
        };
        Object.assign(variablesObj, varObj);
      }
    }

    (convertedSupplier as SupplierSettings).variable_mapping = variablesObj;

    return convertedSupplier;
  }

  /**
   * Convert the variable mapping object back into an array
   * @param variablesObj mapped variables object
   * @returns variables object as an array
   */
  private _convertVariableMappingPayload(
    supplier: SupplierSettings
  ): SupplierSettingsBackend {
    const variablesObj = supplier.variable_mapping;
    const variable_mapping: VariableMapping[] = [];
    const convertedSupplier: SupplierSettingsBackend = {
      ...supplier,
      variable_mapping,
    };

    if (!variablesObj) {
      return convertedSupplier;
    }
    Object.keys(variablesObj).forEach((key) => {
      if (
        [
          'ps_supplier_sid',
          'ps_supplier_respondent_id',
          'ps_custom_svar1',
          'ps_custom_svar2',
          'ps_rstatus',
          'ps_hash',
        ].includes(key) &&
        variablesObj[key]?.incoming
      ) {
        variable_mapping.push({
          [key]: {
            incoming: variablesObj[key].incoming,
            outgoing: variablesObj[key].outgoing
              ? variablesObj[key].outgoing
              : variablesObj[key].incoming,
          },
        });
      } else if (
        ['ps_oqid', 'ps_term_rsn'].includes(key) &&
        variablesObj[key] &&
        variablesObj[key]?.outgoing
      ) {
        variable_mapping.push({
          [key]: {
            outgoing: variablesObj[key].outgoing,
          },
        });
      }
    });

    return convertedSupplier;
  }
}

interface ManageSupplierSettingsResponse {
  apiStatus: string;
  supplier: SupplierSettingsBackend[];
}

export interface SupplierSettingsBackend {
  cmp: number;
  dynamicProfiler: string;
  isAsrRedirectionEnable: boolean;
  isExpose: boolean;
  isNotify: boolean;
  supp_url_completes?: any;
  supp_url_overquota?: any;
  supp_url_terms?: any;
  supplier_type: string;
  variable_mapping?: VariableMapping[];
}

export interface SupplierSettings {
  cmp: number;
  dynamicProfiler: string;
  isAsrRedirectionEnable: boolean;
  isExpose: boolean;
  isNotify: boolean;
  supp_url_completes?: any;
  supp_url_overquota?: any;
  supp_url_terms?: any;
  supplier_type: string;
  variable_mapping?: VariableMapping;
}

export interface VariableMapping {
  [key: string]: {
    incoming?: string;
    outgoing?: string;
  };
}
